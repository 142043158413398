<template>
  <div class="flex flex-col items-center pb-10">
    <div class="w-full max-w-full px-4 xl:px-0 xl:max-w-screen-lg">
      <div class="flex items-center justify-center max-w-screen-lg w-full my-6">
        <Logotype class="w-auto h-7 navbar__logo" />
      </div>
      <div class="flex items-center justify-center gap-6 mb-9">
        <div class="bg-blue-300 h-1.5 w-44 sm:w-112 md:w-full rounded-md relative">
          <span class="absolute left-0 rounded-md top-0 h-full w-full bg-blue-800 "></span>
        </div>
      </div>
      <div class="flex flex-col gap-5 lg:gap-0 w-full">
        <div class="flex flex-col gap-5 lg:gap-3 w-full">
          <h4
            class="my-0 lg:my-8 lg:mb-12 font-serif text-3xl leading-9 lg:text-4xl lg:leading-10 font-medium text-black text-center"
          >
          Create Your Account
          </h4>
        </div>
        <div class="flex flex-col gap-2 w-full max-w-sm mx-auto">
          <form @submit.prevent="submit" novalidate="true" >

          <InputGroup
              testid="onboarding_signup_email"
              label="EMAIL ADDRESS"
              placeholder="Enter your email address"
              type="email"
              autofocus
              :disabled="true"
              :value="form.user.email"
              autocapitalize="off"
            />
            <InputGroup
              testid="onboarding_signup_name"
              label="FULL NAME"
              placeholder="Enter your full name"
              type="text"
              v-model="form.user.account_attributes.name"
              :errors="form.errors.name&&[form.errors.name]"
              error
              autofocus
              autocapitalize="off"
            />
            <InputGroup
              testid="onboarding_signup_password"
              label="PASSWORD"
              placeholder="Enter your password"
              type="password"
              v-model="form.user.password"
              :errors="form.errors.password&&[form.errors.password]"
              autofocus
              autocapitalize="off"
            />
            <div class="mt-3.5 mb-5 lg:mb-12">
              <Checkbox
                testid="onboarding_terms_and_privacy"
                 v-model="form.user.account_attributes.notifications_enabled"
                 :errors="form.errors.notifications_enabled"
                class=" text-gray-900 font-normal text-base text-left customCheckBox"
              >
                Keep me informed about special offers and course releases.
              </Checkbox>
            </div>
            <button type="submit" class="button button--blue w-full text-base max-w-15 mx-auto" > Next</button>
            </form>
            <div class="">
              <p class="text-center mt-1 lg:mt-0 text-xs leading-5 text-blue-800 font-normal mx-auto w-48 ">By creating an account, I agree to the  <a class="underline" :href="$routes.terms_of_use()" target="_blank">Terms of Use</a> and <a class="underline" :href="$routes.privacy_policy()" target="_blank">Privacy policy  </a></p>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@/Layouts/ReducedLayout'
import Logotype from '@/Components/Shared/Logotype'
import authForm from '@/mixins/authForm'
import Checkbox from '@/Components/Shared/Forms/Checkbox'

export default {
  mixins: [
    authForm
  ],

  layout: Layout,

  components: {
    Logotype,
    Checkbox
  },

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      form: this.$inertia.form({
        user: {
          email: this.user.email,
          password: null,
          terms_and_privacy: true,
          account_attributes: {
            name: null,
            notifications_enabled: true
          }
        },
        errors: {
          name: null,
          password: null
        }
      })
    }
  },

  methods: {
    validPassword(password) {
      return password?.length >= 6
    },

    submit() {
      const {  password, account_attributes: { name } } = this.form.user
      this.form.errors = {}

      const isValidPassword = this.validPassword(password)

      if (name && isValidPassword) {
        this.form.put(this.$routes.user_registration(), {
          headers: {
            'X-NEXT-PATH': this.getQueryParam('redirect_to') ?? this.next
          }
        })
      } else {
        if (!password) {
          this.form.errors.password = 'Enter password'
        } else if (!isValidPassword) {
          this.form.errors.password = 'Enter valid password'
        }

        if (!name) {
          this.form.errors.name = 'Enter name'
        }
      }
    }
  }
}
</script>
