export const currentUser = {
  computed: {
    currentUser() {
      return this.$page.props.auth.user
    },
    hasEnrollments() {
      if (!this.currentUser) {
        return false
      }

      return this.currentUser.accessible_courses.length > 0
    },
    isEligibleForMembership() {
      return this.currentUser?.eligible_for_membership
    },
    isSubscribed() {
      return this.currentUser?.active_membership || this.currentUser?.account?.subscription_experiment_enabled ? true : false
    },
    isAdmin() {
      return this.$page.props.auth.isAdmin
    },
    enrolled() {
      return this.isEnrolled(this.course?.id)
    }
  },

  methods: {
    isEnrolled(courseId) {
      if (!this.currentUser || !courseId) {
        return false
      }
      const courses = this.currentUser.accessible_courses
      return courses.some(each => each.course_id === courseId)
    },
    isOwned(courseId) {
      if (!this.currentUser) {
        return false
      }
      const courses = this.currentUser.accessible_courses
      return courses.some(each => each.course_id === courseId && !each.is_from_membership)
    }
  }
}

export default currentUser
