<template>
  <div class="custom-video-player">

    <BrightcovePlayer
      class="video-ratio-16-9"
      v-bind="$attrs"
      @onPlaying="handlePlaying"
      ref="brightcovePlayer"
      @onWaiting="onWaiting"
      @onPlay="onPlay"
      @onAutoPlay="onAutoPlay"
      @onPause="handlePause"
      @onEnded="onEnded"
      @onFullscreenChange="onFullscreenChange"
      @onTimeupdate="onTimeupdate"
      @onCanPlayThrough="onCanPlayThrough"
      @onError="onError"
    />
    <!-- <div class="absolute left-0 top-0 w-full h-full" v-if="showThumbnail">
      <img
          :data-src="this.landingBanner.url"
          :srcset="this.landingBanner.srcset"
          alt="rita_banner_image_alt"
          class="image-cover lazyload"
          @load="imageLoaded"
          loading="lazy"

        />
    </div> -->
    <div v-if="!isPlaying && showPlayButton" @click="triggerPlay"  class=" custom-play-button delay-1000 border py-3 z-10 px-8 bg-white border-white flex gap-2">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 2L21 11.9447L5 22V2ZM5.91725 3.65011V20.3402L19.2693 11.949L5.91725 3.65011Z" fill="#0E1E38"/>
      </svg>
        <span  class=" tracking cursor-pointer text-base text-blue-800 font-normal">WATCH TRAILER</span>

    </div>

  </div>

</template>


<script>
import throttle from 'lodash/throttle'
import mixpanel from '@/mixins/mixpanel'
import BrightcovePlayer from '@/vendor/brightcove/BrightcovePlayer'

const VIDEO_ACTION_EVENT = 'video_action'
const VIDEO_EVENT = 'video_event'
const VIDEO_STREAMED_EVENT = 'video_streamed'
const VIDEO_PROGRESS_EVENT = 'video_progress'
const VIDEO_ERROR_EVENT = 'video_error'
const PROGRESS_TRACKING_POINTS = [75, 50, 25]

export default {
  mixins: [
    mixpanel
  ],

  components: {
    BrightcovePlayer
  },

  props: {
    mixpanel: {
      type: Object,
      defaut: () => ({})
    }
  },
  data() {
    return {
      isPlaying: false, // Add this line to track play state
      showThumbnail: true,
      showPlayButton: false
    }
  },
  mounted() {

    setTimeout(() => {
      this.showPlayButton = true
    }, 2000)

    this.$nextTick(() => {
        if (this.$refs.brightcovePlayer && typeof this.$refs.brightcovePlayer.apiCallToHidePlayButton === 'function') {
          this.$refs.brightcovePlayer.apiCallToHidePlayButton()
        }
    })

  },
  methods: {

    triggerPlay() {
      this.isPlaying = true
      this.showThumbnail = false
      this.$refs.brightcovePlayer.play()
    },

      handlePlaying() {
      this.isPlaying = true
      // Call the original method or emit an event if necessary
      this.onPlaying()
    },

    handlePause() {
      this.isPlaying = false
      this.showThumbnail = false
      this.showPlayButton = false
      // Call the original method or emit an event if necessary
      this.onPause()
    },

    onTimeupdate: throttle(function(e, player) {
      // this try block is used to make sure that when currentTime or duration methods are called
      // a javascrip error is not thrown in case the error is a typeError
      // this is occuring after we abruptly closed  the video player
      // e.g. the popup is closed without stopping the video, the user navigated to another page while the video was playing
      // this is error is happening because we are thottling the function and by the time it runs there is no player on the page
      let currentTime
      let duration
      try {
        currentTime = player?.currentTime()
        duration = player?.duration()
      }
      catch(e) {
        if (e instanceof TypeError) { return }
        else { throw e }
      }

      if (!currentTime || !duration) return
      this.$emit('onTimeupdate', currentTime)

      // Calculate current video progress
      const progress = this.calcPercentage(currentTime, duration)

      if (this.previousProgress === null) {
        // Calculate previously known progress based on autoPlayAt
        const { autoPlayAt } = this.$attrs
        this.previousProgress = this.calcPercentage(autoPlayAt || 0, duration)
      }

      // Determine if progress reached a progress tracking point
      for (const each of PROGRESS_TRACKING_POINTS) {
        if (this.previousProgress < each && progress >= each) {
          this.trackVideoProgress(each)
          break
        }
      }

      // Set current progress as the new previous progress
      this.previousProgress = progress
    }, 5000),

    calcPercentage(numerator, denominator) {
      return Math.round(numerator / denominator * 100)
    },

    onFullscreenChange(e) {
      const isFullscreen = e.target.classList.contains('vjs-fullscreen')
      this.trackVideoAction(isFullscreen ? 'fullscreen' : 'inline')
    },

    onPlaying() {
      this.trackVideoEvent('playing')
      this.startTimeEvent()
    },

    onWaiting() {
      this.trackVideoEvent('waiting')
      this.trackVideoStreamed({ previousEvent: 'waiting' })
    },

    onPlay(e, player) {
      this.trackVideoAction('play')
      this.$emit('onPlay', player)
    },

    onAutoPlay(e, player) {
      this.trackVideoAction('auto_play')
      this.$emit('onAutoPlay', player)
    },

    startTimeEvent() {
      this.timeEvent(VIDEO_STREAMED_EVENT)
      this.streaming = true
    },

    onPause() {
      this.trackVideoAction('pause')
      this.trackVideoStreamed({ previousEvent: 'pause' })
    },

    onEnded(e, player) {
      this.trackVideoProgress(100)
      this.$emit('onEnded', player)
    },

    onCanPlayThrough(e, player) {
      this.$emit('onCanPlayThrough', player)
    },

    onError(type = 'error') {
      this.trackEvent(VIDEO_ERROR_EVENT, { ...this.mixpanel, type })
    },

    trackVideoProgress(percentage) {
      this.trackEvent(VIDEO_PROGRESS_EVENT, { ...this.mixpanel, percentage })
      this.$emit('onTrackVideoProgress', percentage)
    },

    trackVideoStreamed({ previousEvent = 'unload' }) {
      if (this.streaming) {
        this.streaming = false
        this.trackEvent(VIDEO_STREAMED_EVENT, { ...this.mixpanel, previousEvent })
      }
    },

    trackVideoEvent(type) {
      this.trackEvent(VIDEO_EVENT, { ...this.mixpanel, type })
    },

    trackVideoAction(type) {
      this.trackEvent(VIDEO_ACTION_EVENT, { ...this.mixpanel, type })
    }
  },

  created() {
    this.previousProgress = null
    this.streaming = false
    this.trackVideoAction('open')
    window.addEventListener('beforeunload', this.trackVideoStreamed) // leave CA platform
    this.landingBanner = {
      url: require('@/assets/images/landing/rita-konig-banner.webp'),
      sizes: {
        large: require('@/assets/images/landing/rita-konig-banner.webp'),
        medium: require('@/assets/images/landing/rita-konig-banner.webp'),
        small: require('@/assets/images/landing/rita-konig-banner.webp')
      }
    }
  },

  beforeDestroy() {
    this.trackVideoStreamed({ previousEvent: 'destroy' }) // leave page but keep on the platform
    window.removeEventListener('beforeunload', this.trackVideoStreamed)
  }
}
</script>
