<template>
  <div class="menu__account relative">
    <template v-if="currentUser">
      <div
       data-testid="navbar-profile"
        @click="$emit('toggleAccountDropdown')"
        :aria-label="$I18n.t('link_labels.go_to_account', i18nScope)"
        class="relative w-12 h-12 overflow-hidden rounded-full cursor-pointer ml-6"
      >
        <img loading="lazy" :src="currentUser.account.avatar_path" :alt="$I18n.t('link_labels.account_avatar', i18nScope)" class="image-cover">
      </div>
      <div class="menu-container" v-show="isAccountDropdown" @click.self="$emit('toggleAccountDropdown')" ></div>

      <div
        class="absolute w-60 top-12 -right-6 px-6 py-4 bg-blue-800 shadow-sm flex flex-col z-50"
        :class="isAccountDropdown ? 'block' : 'hidden'"
      >
        <!-- <div class="flex flex-row items-center">
          <div class="w-12 h-12 overflow-hidden rounded-sm">
            <img loading="lazy" :src="currentUser.account.avatar_path" :alt="$I18n.t('link_labels.account_avatar', i18nScope)" class="image-cover">
          </div>

          <div class="profile__info ml-2">
            <p class="text-xs font-light mb-1">{{ $I18n.t('welcome', i18nScope) }}</p>
            <p data-testid="current-user-desktop" class="font-normal">{{ currentUser.account.name }}</p>
          </div>
        </div> -->

        <Link
          v-if="hasEnrollments && !isSubscribed"
          data-testid="navbar-link-courses-desktop"
          :href="$routes.root({ anchor: 'my-courses' })"
          class="menu-link py-2"
        >
          {{ $I18n.t('home', i18nScope) }}
        </Link>
        <Link
          :href="$routes.user_collections()"
          class="menu-link py-2"
        >
          {{ $I18n.t('collections', i18nScope) }}
        </Link>

        <!-- <Link data-testid="navbar-link-collections" :href="$routes.root()" class="menu-link py-2">{{ $I18n.t('collections', i18nScope) }}</Link> -->
        <a data-testid="navbar-link-rewards" href="https://page.createacademy.com/members-rewards" target="_blank" class="menu-link py-2">{{ $I18n.t('offers.rewards.title', i18nScope) }}</a>

        <Divider color="light" spacing="xs" />

        <ProfileLinks />

        <Divider color="light" spacing="xs" />

        <Link data-testid="navbar-link-support" :href="$routes.support()" class="menu-link py-2">{{ $I18n.t('support', i18nScope) }}</Link>

        <LogoutButton data-testid="logout-button" class="subheading--medium text-danger py-1 text-left outline-none border-0 focus:outline-none" />
      </div>
    </template>
    <template>
      <div class=" flex justify-between items-center gap-2">
      <Link
        v-if="!currentUser"
        data-testid="navbar-link-signin"
        :href="$routes.new_user_session()"
        :aria-label="$I18n.t('link_labels.sign_in', i18nScope)"
        class="menu-link px-6"
      >
        Log In
      </Link>
        <ButtonLink
          v-show="!isSubscribed"
          data-testid="button-membership-navbar"
          :href="$routes.onboarding()"
          @click="trackSubscribeButton"
          v-bind="$attrs"
          type="submit"
          class="text-sm border-transparent"
          variant="white"
          :class="{'ml-6':currentUser}"
          outline
        >
          SUBSCRIBE
        </ButtonLink>
        </div>
    </template>
  </div>
</template>


<script>
import currentUser from '@/mixins/currentUser'
import mixpanel from '@/mixins/mixpanel'

import { Link } from '@inertiajs/inertia-vue'
import Divider from '@/Components/Shared/Divider'
import ProfileLinks from '@/Components/Shared/Objects/Navbar/ProfileLinks'
import LogoutButton from '@/Components/Shared/LogoutButton'
import ButtonLink from '@/Components/Shared/ButtonLink'

export default {
  mixins: [
    currentUser,
    mixpanel
  ],

  components: {
    Link,
    Divider,
    ProfileLinks,
    LogoutButton,
    ButtonLink
  },

  props: {
    isAccountDropdown: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    trackSubscribeButton() {
      this.trackEvent('subscribe_button', {
        userType: this.currentUser ? 'authed' : 'anonymous',
        page: window.location.pathname,
        component: 'Nav Bar'
      })
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.navbar' }
  }
}
</script>
