<template>
  <div data-testid="course-talent" class="relative">
    <div :data-testid="`course-talent-${course.id}`">
      <Tags
        v-if="enrolled && course.hasNewLessons"
        :data-testid="`course-talent-${course.id}-new-lessons`"
        color="success"
        size="small"
        class="z-10 absolute top-2 left-4 sm:left-6"
      >
        <span class="overline--medium overline--widest">{{ $I18n.t("new_lessons", i18nScope) }}</span>
      </Tags>

      <div class="relative h-64 w-full overflow-hidden mb-4">
        <!-- Sale Tag -->
        <SaleTag
          v-if="showSaleTag && !enrolled && !comingSoon"
          :course="course"
          class="left-4 max-w-28"
        />

        <div
          :data-testid="`course-talent-${course.id}-image-cta`"
          @click="onTalentClick()"
          class="h-full text-blue-800 cursor-pointer"
        >
          <img loading="lazy"
            :data-testid="`course-talent-${course.id}-image`"
            :data-src="course.image"
            :alt="`${course.name} ${$I18n.t('thumbnail', i18nScope)}`"
            class="lazyload image-cover"
          />
        </div>

        <template v-if="!enrolled">
          <!-- ADD COURSE TO BASKET -->
          <PurchaseButton v-if="showPurchaseButton"
            :course="course"
            :gtmPageName="gtmPageName"
            :gtmSectionName="gtmSectionName"
            :abTestVariants="abTestVariants"
            size="small"
            class="absolute bottom-4 right-4 z-10"
          />

          <template v-else>
            <!-- IF LOGGED IN & HAS FREE LESSON ROUTE -->
            <template v-if="currentUser && freeLessonRoute">
              <ButtonLink
                :data-testid="`course-talent-${course.id}-free-lesson-button`"
                :href="freeLessonRoute"
                @click="onFreeLessonClick()"
                icon="Play"
                inverse
                size="small"
                class="absolute bottom-4 md:bottom-3 right-2 z-10"
              >
                  {{ $I18n.t("watch_free_lesson", i18nScope) }}
              </ButtonLink>
            </template>

            <!-- IF LOGGED OUT OR IF DOESN'T HAVE FREE LESSON ROUTE -->
            <template v-else>
              <TrailerButton
                :data-testid="`course-talent-${course.id}-trailer-button`"
                :course="course"
                :brightcove="brightcove"
                :gtmPageName="gtmPageName"
                :gtmSectionName="gtmSectionName"
                type="button"
                inverse
                size="small"
                class="absolute bottom-4 md:bottom-3 right-2 z-10"
              />
            </template>

            <!-- UPCOMING COURSE TAG -->
            <Tags
              v-if="comingSoon"
              :data-testid="`course-talent-${course.id}-coming-soon-tag`"
              size="large"
              class="absolute bottom-4 left-0"
            >
              {{ $I18n.t("coming_soon", i18nScope) }}
            </Tags>
          </template>
        </template>

        <!-- OWNED COURSE -->
        <div v-if="owned">
          <Tags
            :data-testid="`course-talent-${course.id}-owned-tag`"
            color="blue"
            class="absolute top-0 right-6 flex flex-col items-center"
          >
            <Icon name="Finished" class="text-xl my-2" />
            <p class="overline--medium overline--widest whitespace-pre-wrap">{{ $I18n.t('owned_course', i18nScope) }}</p>
          </Tags>
        </div>
      </div>

      <div
        :data-testid="`course-talent-${course.id}-cta`"
        @click="onTalentClick()"
        class="cursor-pointer"
      >
        <div class="flex flex-row no-wrap justify-between">
          <component
            :is="headingLevel"
            :data-testid="`course-talent-${course.id}-name`"
            class="title-medium text-blue-800 mb-0"
            >
            {{ course.name }}</component
          >
          <span v-if="course.isAGift" class="font-medium text-lg tracking-wide">{{
            course.campaign.humanizedPrice
          }}</span>
          <Icon v-else name="Arrow" class="text-2xl" />
        </div>
        <p
          :data-testid="`course-talent-${course.id}-teacher-name`"
          class="subheading"
        >
          {{ course.teacher.name }}
        </p>

        <div
            v-if="enrolled && preLaunch"
            :data-testid="`course-talent-${course.id}-release-date`"
            class="mt-2"
          >
            <PreLaunchTag :course="course" :i18nScope="i18nScope" class="overline--medium text-yellow-800" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import statuses from '@/mixins/statuses'
import currentUser from '@/mixins/currentUser'
import generalAnnouncement from '@/mixins/generalAnnouncement'
import saleTag from '@/mixins/saleTag'
import gtm from '@/mixins/gtm'
import mixpanel from '@/mixins/mixpanel'
// AB Testing bits
import abTest from '@/mixins/abTest'

import { Link } from '@inertiajs/inertia-vue'

import ButtonLink from '@/Components/Shared/ButtonLink'
import Icon from '@/Components/Shared/Icon'
import PurchaseButton from '@/Components/Shared/Courses/PurchaseButton'
import SaleTag from '@/Components/Shared/Campaigns/SaleTag'
import Tags from '@/Components/Shared/Tags'
import TrailerButton from '@/Components/Shared/Trailer/TriggerButton'
import PreLaunchTag from '@/Components/Shared/Courses/PreLaunchTag'


export default {
  mixins: [
    statuses,
    currentUser,
    generalAnnouncement,
    saleTag,
    gtm,
    mixpanel,
    abTest
  ],

  components: {
    Link,
    ButtonLink,
    Icon,
    PurchaseButton,
    SaleTag,
    Tags,
    TrailerButton,
    PreLaunchTag
  },

  props: {
    course: {
      type: Object,
      required: true
    },
    brightcove: {
      type: Object,
      required: true
    },
    headingLevel: {
      type: String,
      default: 'h2'
    },
    gtmPageName: {
      type: String,
      required: true
    },
    gtmSectionName: {
      type: String,
      required: true
    },
    gtmPosition: {
      type: Number,
      required: true
    },
    showPurchaseButton: {
      type: Boolean,
      default: false
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.home.talents' }
  },

  computed: {
    courseLink() {
      if (this.enrolled && this.published) {
        return this.$routes.course_lessons(this.course.slug)
      } else {
        return this.$routes.course(this.course.slug)
      }
    },
    owned() {
      return this.isOwned(this.course.id)
    },
    freeLessonRoute() {
      if (!this.course.freeLessonSlug) { return false }

      return this.$routes.course_lesson(this.course.slug, this.course.freeLessonSlug)
    },
    mixpanelEventData() {
      return {
        page: window.location.pathname,
        component: this.gtmSectionName,
        course: this.course.name,
        teacher: this.course.teacher.name
      }
    }
  },

  methods: {
    onTalentClick() {
      this.gtmAddProductListingImpressionClick(this.gtmPageName, this.gtmSectionName, this.course, this.gtmPosition)

      this.trackEvent('go_to_course_page', this.mixpanelEventData)

      this.$inertia.visit(this.courseLink)
    },

    onFreeLessonClick() {
      this.trackEvent('click_free_lesson', this.mixpanelEventData)
    }
  }
}
</script>
