<template>
  <div v-if="show" data-testid="countdown" class="inline">
    <span data-testid="general-announcement-countdown-pre-text">
      {{ genAnnouncement.countdownPreText }} {{ formattedDays }}:{{
        formattedHours
      }}:{{ formattedMinutes }}:{{ formattedSeconds }}
    </span>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'

export default {
  name: 'Countdown',
  props: {
    genAnnouncement: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const days = ref(0)
    const hours = ref(0)
    const minutes = ref(0)
    const seconds = ref(0)
    let intervalId = null

    const showAtDate = computed(() =>
      Date.parse(props.genAnnouncement.countdownStartDate)
    )
    const hideAtDate = computed(() =>
      Date.parse(props.genAnnouncement.countdownEndDate)
    )

    const show = computed(() => {
      const dateNow = Date.now()
      if (!props.genAnnouncement.campaignEndDate) return false
      if (
        props.genAnnouncement.countdownStartDate &&
        dateNow < showAtDate.value
      )
        return false
      if (props.genAnnouncement.countdownEndDate && dateNow > hideAtDate.value)
        return false
      return true
    })

    const updateCountdown = () => {
      const now = new Date().getTime()
      const endTime = new Date(props.genAnnouncement.campaignEndDate).getTime()
      const distance = endTime - now

      if (distance < 0) {
        clearInterval(intervalId)
        days.value = hours.value = minutes.value = seconds.value = 0
        return
      }

      days.value = Math.floor(distance / (1000 * 60 * 60 * 24))
      hours.value = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      minutes.value = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      seconds.value = Math.floor((distance % (1000 * 60)) / 1000)
    }

    const formatTime = (time) => {
      return time < 10 ? `0${time}` : time
    }

    const formattedDays = computed(() => formatTime(days.value))
    const formattedHours = computed(() => formatTime(hours.value))
    const formattedMinutes = computed(() => formatTime(minutes.value))
    const formattedSeconds = computed(() => formatTime(seconds.value))

    onMounted(() => {
      updateCountdown()
      intervalId = setInterval(updateCountdown, 1000)
    })

    onBeforeUnmount(() => {
      clearInterval(intervalId)
    })

    return {
      formattedDays,
      formattedHours,
      formattedMinutes,
      formattedSeconds,
      show
    }
  }
}
</script>
