var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('MetaTags',{attrs:{"metaTags":_vm.metaTags}}),_vm._v(" "),_c('div',{attrs:{"data-testid":"year-of-learning-banner"}},[_c('div',{staticClass:"banner_custom"},[_c('img',{staticClass:"relative w-full image-cover",attrs:{"loading":"lazy","data-testid":"year-of-learning-banner-image","src":_vm.HeadBanner.url,"data-src":_vm.HeadBanner.url}})]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"relative w-full"},[_c('div',{staticClass:"container py-0"},[_c('div',{staticClass:"flex gap-8 items-center overflow-x-auto w-full py-3 lg:py-3.5 tab_scroll"},[_c('div',{staticClass:"py-2 text-sm font-sans text-blue-800 uppercase font-normal cursor-pointer",class:{
            'active border-b-2 border-blue-800 font-medium':
              _vm.selectedMonth === 'all',
          },on:{"click":function($event){return _vm.changeTab('all')}}},[_vm._v("\n          All\n        ")]),_vm._v(" "),_vm._l((_vm.months),function(month){return _c('div',{key:month,staticClass:"text-sm font-sans text-blue-800 py-2 uppercase font-normal cursor-pointer",class:{
            'active border-b-2 border-blue-800 font-medium':
              _vm.selectedMonth === month,
          },on:{"click":function($event){return _vm.changeTab(month)}}},[_vm._v("\n          "+_vm._s(month)+"\n        ")])})],2)])]),_vm._v(" "),_c('div',[_c('MonthlySection',{attrs:{"courses":_vm.courses}})],1),_vm._v(" "),_c('section',{staticClass:"bg-blue-500"},[_c('div',{staticClass:"container px-4 lg:px-16 py-7 lg:py-16"},[_c('div',{staticClass:"font-serif text-center font-medium mb-6 md:mb-8 text-4xl md:text-6xl text-white w-4/5 md:w-auto max-w-screen-md mx-auto"},[_vm._v("\n        Start your year of creative learning today\n      ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-row flex-wrap w-full md:w-auto justify-center items-center gap-x-6 gap-y-4 mb-2 lg:mb-3"},[_c('a',{staticClass:"button button--yellow",attrs:{"href":"/membership"},on:{"click":_vm.trackSubscribeButton}},[_c('span',{staticClass:"text-base font-normal tracking-wider"},[_vm._v("Join for "+_vm._s(_vm.getGeoPrice(_vm.membershipPlan.monthlyPrice))+"/month")])])]),_vm._v(" "),_c('div',{staticClass:"mt-2 font-normal text-sm lg:text-base text-grayscale-100 lg:text-blue-100 text-center"},[_vm._v("\n        (Billed annually)\n      ")])])]),_vm._v(" "),_c('div',{staticClass:"fixed bottom-3 right-3 transition-opacity bg-blue-500 w-10 h-10 shadow-lg cursor-pointer flex items-center justify-center",class:_vm.showGoToTop ? 'opacity-100 z-60' : 'opacity-0 hidden',on:{"click":_vm.goToTop}},[_c('Icon',{staticClass:"text-base md:text-xl text-white rotateIcon",attrs:{"name":"Arrow"}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-green-500"},[_c('div',{staticClass:"container px-4 py-7 lg:px-16 lg:pt-10 lg:pb-16"},[_c('h2',{staticClass:"text-4xl lg:title-largester text-grayscale-100 font-medium font-serif text-center mb-4"},[_vm._v("\n          A Year of Joyful Learning\n        ")]),_vm._v(" "),_c('p',{staticClass:"text-lg lg:text-2xl leading-8 font-normal font-sans text-grayscale-100 tracking-wide text-center"},[_vm._v("\n          Learn throughout the seasons with our expert-led creative courses.\n          You'll discover how to transform your home, ideas for a flourishing\n          garden, new seasonal recipes and step-by-step craft projects that\n          will boost your creativity all year long.\n        ")])])])
}]

export { render, staticRenderFns }