<template>
    <div class="flex flex-col items-center pb-10 lg:pb-0">
        <MetaTags :metaTags="metaTags" />

        <div class="w-full max-w-full xl:px-0 xl:max-w-screen-lg">
            <div class="flex items-center justify-center max-w-screen-lg w-full my-6 cursor-pointer" @click="navigateHome">
                <Logotype class="w-auto h-7 navbar__logo" />
            </div>
            <div class="flex items-center justify-center gap-6 mb-9">
                <a @click="goBack" class=" cursor-pointer">
                    <Icon name="Arrow" class="text-base md:text-xl text-black leftArrow" />
                </a>
                <div class="bg-blue-300 h-1.5 w-8/12 sm:w-112 md:w-full rounded-md relative">
                    <span class="absolute left-0 rounded-md top-0 h-full   bg-blue-800 w-2/4"></span>
                </div>
            </div>
            <div class="flex flex-col gap-5 lg:gap-9 w-full">
                <div class="flex flex-col gap-5 px-4 lg:px-0 lg:gap-3 w-full">
                    <h4 class="font-serif text-3xl leading-9 lg:text-4xl lg:leading-10 font-medium text-black text-center">
                        We have {{ filteredLessonsCount }} lessons we think you’ll love
                    </h4>
                    <p class="text-lg mb-3 lg:mb-0 lg:text-2xl font-normal text-center text-black">Plus, another <span
                            class="text-yellow-800">{{ totalLessonsCount - filteredLessonsCount }} lessons</span> waiting to be explored</p>
                </div>
                <div class="flex items-start justify-start flex-wrap w-128 max-w-full lg:w-full mx-auto">
                    <div class="w-full hidden lg:block lg:w-7/12">
                        <div class="flex flex-col md:flex-row md:flex-wrap w-full mx-0 md:-mx-3 course-maxHeight">
                            <CourseTeaser v-for="course in courses" :key="course.id" :course="course" />
                        </div>
                    </div>
                    <div class="block lg:hidden w-full">
                        <img loading="lazy" class="image-cover w-auto max-w-full lazyload" alt=""
                            src="@/assets/images/backgrounds/onboarding-courses-mob.png" />
                    </div>
                    <div class="w-full lg:w-5/12">
                        <div v-if="currentUser" class="bg-neutral-100 py-5 px-4 lg:py-12 lg:px-8 flex flex-col gap-5 lg:gap-9">
                          <p class="text-base lg:text-2xl font-normal lg:leading-8 text-black text-center">Access all courses with an annual subscription</p>
                          <a class="button button--blue w-full text-base mx-auto" @click="submit"> Next</a>
                          <div class="mt-8 hidden lg:flex flex-col gap-4">
                            <div class="mt-4 text-7xl font-serif leading-10 transform rotate-180 text-right tracking-tighter mb-0">
                              ,,
                            </div>
                            <p class="-mt-8 text-xl  leading-8 tracking-wide text-black font-normal">I love Create Academy! There is so much talent and inspiration.</p>
                            <div class="flex items-center gap-3 text-base tracking-wider uppercase font-normal text-blue-500">
                              <span>from GWEN</span>
                              <span>|</span>
                              <span>TRUSTPILOT</span>
                            </div>
                            <div>
                              <svg xmlns="http://www.w3.org/2000/svg" width="109" height="15" viewBox="0 0 109 15" fill="none">
                                <path d="M7.58015 11.0952L10.8743 10.2484L12.2499 14.5492L7.58015 11.0952ZM15.1603 5.53661H9.36237L7.58015 0L5.79794 5.53661H0L4.69267 8.96737L2.91045 14.504L7.60313 11.0732L10.4906 8.96874L15.1603 5.53661Z" fill="#00B67A"/>
                                <path d="M31.045 11.0952L34.3392 10.2484L35.7147 14.5492L31.045 11.0952ZM38.6251 5.53661H32.8272L31.045 0L29.2628 5.53661H23.4648L28.1575 8.96737L26.3753 14.504L31.068 11.0732L33.9555 8.96874L38.6251 5.53661Z" fill="#00B67A"/>
                                <path d="M54.4984 11.0952L57.7926 10.2484L59.1681 14.5492L54.4984 11.0952ZM62.0785 5.53661H56.2806L54.4984 0L52.7161 5.53661H46.9182L51.6109 8.96737L49.8287 14.504L54.5213 11.0732L57.4088 8.96874L62.0785 5.53661Z" fill="#00B67A"/>
                                <path d="M77.97 11.0952L81.2642 10.2484L82.6397 14.5492L77.97 11.0952ZM85.5502 5.53661H79.7522L77.97 0L76.1878 5.53661H70.3899L75.0826 8.96737L73.3003 14.504L77.993 11.0732L80.8805 8.96874L85.5502 5.53661Z" fill="#00B67A"/>
                                <path d="M101.42 11.0952L104.714 10.2484L106.089 14.5492L101.42 11.0952ZM109 5.53661H103.202L101.42 0L99.6375 5.53661H93.8396L98.5323 8.96737L96.7501 14.504L101.443 11.0732L104.33 8.96874L109 5.53661Z" fill="#00B67A"/>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div v-else class="bg-neutral-100  py-5 px-4 lg:py-12 lg:px-8 flex flex-col gap-2 lg:gap-10">
                            <p class="text-base text-center lg:text-2xl font-normal lg:leading-8 text-black">Enter your email to get
                                started</p>
                            <div class="xs-label-hidden">
                                <InputGroup testid="onboarding_email" label="EMAIL ADDRESS"
                                    placeholder="Enter your email address" type="email" ref="emailInput"
                                    :errors="emailError ? [emailError] : []" v-model="email" autofocus autocapitalize="off" />
                            </div>
                            <div>
                                <a class="button button--blue w-full text-base mx-auto" @click="submit"> Next</a>
                                <Divider color="neutral-400" class="w-full" label="or"></Divider>

                                <SocialButtons :formAuthenticityToken="formAuthenticityToken" :i18nScope="i18nScope"
                                    :queryParameters="queryParameters"  :redirectUri="$routes.onboarding_checkout_redirect()" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Layout from '@/Layouts/ReducedLayout'
import currentUser from '@/mixins/currentUser'
import Logotype from '@/Components/Shared/Logotype'
import Icon from '@/Components/Shared/Icon'
import CourseTeaser from '@/Pages/UserOnboarding/CourseTeaser'
import MetaTags from '@/Components/Shared/MetaTags'

import authForm from '@/mixins/authForm'
import { metaTags } from '@/mixins/metaTags'
import mixpanel from '@/mixins/mixpanel'

export default {
    mixins: [
        metaTags,
        authForm,
        currentUser,
        mixpanel
    ],

    layout: Layout,

    components: {
        MetaTags,
        Logotype,
        Icon,
        CourseTeaser
    },

    data() {
        return {
            email: sessionStorage.getItem('onboarding_email'),
            emailError: ''
        }
    },

    props: {
        courses: {
            type: Array,
            default: () => []
        },
        filteredLessonsCount: {
            type: Number,
            default: 0
        },
        totalLessonsCount: {
            type: Number,
            default: 0
        }
    },

    methods: {
    goBack() {
        this.$inertia.visit(this.$routes.onboarding())
    },
      trackUserEmail() {
        this.trackEvent('onboarding_user_email', {
          page: window.location.pathname,
          email: this.currentUser ? this.currentUser.email : this.email
        })
      },

        submit() {
            if (this.emailIsValid || this.currentUser) {
                this.trackUserEmail()

                sessionStorage.setItem('onboarding_email', this.email)
                this.$inertia.visit(this.$routes.onboarding_submit_email(), {
                    method: 'post',
                    data: {
                        email: this.currentUser? null : this.email
                    }
                })
            } else {
                this.emailError = 'Please enter a valid email address'
                this.$refs.emailInput ?.focus()

            }
        },
    navigateHome() {
      this.$inertia.visit(this.$routes.root())
    }
    },
    computed: {

        emailIsValid() {
            const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            return pattern.test(this.email)
        }
    },
    watch: {
        email(newVal) {
            if (!newVal) {
                this.emailError = 'Email is required'
            } else if (!this.emailIsValid) {
                this.emailError = 'Please enter a valid email address'
            } else {
                this.emailError = ''
            }
        }
    },

    created() {
    
    this.i18nScope = { scope: 'pages.auth.sign_up' }
  
    }

}
</script>
