<template>
  <div class="relative flex flex-col pb-6">
    <div class="text-right mb-4">
      <button data-testid="close-player-modal" @click="$emit('close')">
        <Icon name="close" class="text-2xl text-grayscale-100" />
      </button>
    </div>

    <div class="sm:hidden text-grayscale-100 mb-4">
      <h3 class="title-medium mb-2">{{title}}</h3>
      <p class="subheading whitespace-pre-line">{{subtitle}}</p>
    </div>

    <VideoPlayer
      data-testid="modal-player"
      :isHideControls="false"
      :isAutoPlay="true"
      :options="{
        videoId: videoId,
        embedId: 'default',
        ...brightcove
      }"
      :mixpanel="mixpanel"
      @onPlay="onPlay"
      @onAutoPlay="onPlay"
      @onTrackVideoProgress="onTrackVideoProgress"
    />

    <div class="flex flex-row justify-end sm:justify-between items-start mt-6">
      <div class="hidden sm:block text-grayscale-100">
        <h3 class="title-medium mb-2">{{title}}</h3>
        <p class="subheading whitespace-pre-line">{{subtitle}}</p>
      </div>

      <slot />
  </div>
  </div>
</template>

<script>
import gtm from '@/mixins/gtm'
import brightcove from '@/mixins/brightcove'
import VideoPlayer from '@/Components/Shared/VideoPlayer'
import Icon from '@/Components/Shared/Icon'

export default {
  mixins: [
    brightcove,
    gtm
  ],

  components: {
    VideoPlayer,
    Icon
  },

  props: {
    mixpanel: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    videoId: {
      type: String,
      required: true
    },
    gtmPageName: {
      type: String,
      required: true
    },
    gtmSectionName: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      // check if the video already started playing
      startedPlaying: false
    }
  },

  methods: {
    onPlay() {
      if (!this.startedPlaying) {
        this.gtmAddTrailerTracking(this.gtmPageName, this.gtmSectionName, this.title, 0)
        this.startedPlaying = true
      }
    },

    onTrackVideoProgress(percentage) {
      this.gtmAddTrailerTracking(this.gtmPageName, this.gtmSectionName, this.title, percentage)
    }
  }
}
</script>
