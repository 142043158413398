<template>
  <PlayerModal
    data-testid="trailer-modal"
    :title="course.teacher.name"
    :subtitle="course.name"
    :video-id="course.trailer"
    :brightcove="brightcove"
    :mixpanel="{
      type: 'trailer',
      videoId: course.trailer,
      courseName: course.name
    }"
    :gtmPageName="gtmPageName"
    :gtmSectionName="gtmSectionName"
    @close="$emit('close')"
  >

    <PurchaseButton
      :course="course"
      :inCart="itemInCart"
      :gtmPageName="gtmPageName"
      :gtmSectionName="gtmSectionName"
      @success="onSuccess"
      inverse
    />
  </PlayerModal>
</template>

<script>
import statuses from '@/mixins/statuses'
import brightcove from '@/mixins/brightcove'
import gtm from '@/mixins/gtm'

import PlayerModal from '@/Components/Shared/Trailer/Player'
import PurchaseButton from '@/Components/Shared/Courses/PurchaseButton'

export default {
  mixins: [
    brightcove,
    gtm,
    statuses
  ],

  components: {
    PurchaseButton,
    PlayerModal
  },

  data() {
    return {
      itemInCart: this.course.inCart
    }
  },

  props: {
    course: {
      type: Object,
      required: true
    },
    gtmPageName: {
      type: String,
      required: true
    },
    gtmSectionName: {
      type: String,
      required: true
    }
  },

  methods: {
    onSuccess() {
      this.itemInCart = true
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.courses.show' }
  }
}
</script>
