<template>
  <div class="relative my-0" data-testid="gift-banner-section" v-if="banner.active">
      <div class="w-full h-48 sm:absolute sm:h-full">
        <img loading="lazy"
          :data-src="bannerImage.url"
          :srcset="bannerImage.srcset"
          :alt="$I18n.t('banner_image_alt', i18nScope)"
          class="image-cover lazyload"
        >
      </div>

      <div class="relative w-full sm:w-112 m-auto bg-yellow-100 py-10 sm:py-14 px-10 text-green-500 flex flex-col items-center text-center">
          <h1 v-if="banner.title" data-testid="gift-banner-title" class="font-serif text-4xl">{{ banner.title }}</h1>

          <Signature class="h-16 sm:h-20 w-auto mb-10 sm:mb-24" />

          <p
            v-if="banner.subtitle"
            class="font-normal max-w-prose text-xl text-center text-green-500 mb-8 sm:mb-12"
            data-testid="gift-banner-subtitle"
          >
            {{ banner.subtitle }}
          </p>
          <ButtonLink
            :href="'/year-of-learning/all'"
            variant="green"
            data-testid="gift-banner-cta"
          >
            {{ $I18n.t('banner_cta', i18nScope) }}
        </ButtonLink>
      </div>
    </div>
 
</template>
<script>

import imgSrcset from '@/mixins/imgSrcset'

import ButtonLink from '@/Components/Shared/ButtonLink'
import Signature from '@/Components/Shared/JoyfullLearningSignature'

export default {
  mixins: [
    imgSrcset
  ],

  components: {
    ButtonLink,
    Signature
  },

  created() {
    this.i18nScope = { scope: 'pages.gifts' }

    const bannerDefaultImage = {
      url: require('@/assets/images/backgrounds/gift-banner-background.jpg'),
      sizes: {
        large: require('@/assets/images/backgrounds/gift-banner-background.jpg'),
        medium: require('@/assets/images/backgrounds/gift-banner-background-medium.jpg'),
        small: require('@/assets/images/backgrounds/gift-banner-background-small.jpg')
      }
    }

    this.bannerImage = this.imgSrcsetBanner(this.banner.image || bannerDefaultImage)
  },

  computed: {
    banner() {
      return this.$page.props.giftBanner || {}
    }
  }
}
</script>
