<template>
    <!-- class="flex flex-col lg:flex-row items-stretch bg-blue-500 mt-14" -->
    <div
      data-testid="access-all-course-banner"
      :class="[
        'flex',
        'flex-col',
        'lg:flex-row',
        'items-stretch',
        'backgroundState',
      ]"
      :style="{ backgroundImage: `url(${imageUrl})` }"
    >

      <!-- Text Content - Second for smaller screens, remains on the left for lg and above -->
      <div class="container py-14 lg:py-28 relative">
        <div
          class="banner-content flex flex-col justify-center text-left gap-2 lg:gap-4 w-full lg:w-1/2  lg:mb-0"
        >
          <div class="mb-2">
            <div class="mb-2 lg:mb-4 font-medium text-3xl lg:text-4xl text-black font-serif capitalize">
              Access all Courses
            </div>
            <div
              data-testid="monthly-price"
              class="font-normal text-base lg:text-xl text-black"
            >
            Get unlimited access for just <span class="font-medium">{{ getGeoPrice(membershipPlan.monthlyPrice) }}/month*</span>
            </div>
          </div>
          <div class="flex flex-col gap-1 lg:gap-2">
            <div
              v-for="(point, index) in membershipBannerPoints"
              :key="index"
              class="flex flex-row items-top font-normal text-left"
            >
              <Icon name="Check" class="text-black text-base mr-3 mt-1" />
              <p class="text-black text-sm lg:text-base">{{ point }}</p>
            </div>
          </div>
          <p class="text-black font-normal text-base lg:text-xl w-112 max-w-full">Why not try before you buy? Create a free account and access over 50 lessons.</p>

          <div class="mt-2 lg:mt-4 text-left">
            <div class="inline-block text-center w-full xs:w-auto">
              <Link
                class="button button--red inlineBlockBtn w-full xs:w-56 max-w-full"
                data-testid="start-learning-button"
                @click="trackSubscribeButton"
                :href="$routes.onboarding()"
              >
                <!-- <span>Join for {{ getGeoPrice(membershipPlan.monthlyPrice) }}/month</span> -->
                <span class="flex gap-1 items-center justify-center"
                  >Subscribe
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div class="block md:hidden">
        <img class="object-cover w-full" :src="respimageUrl" alt="banner mobile image" />
      </div>
    </div>
  </template>

  <script>
  import mixpanel from '@/mixins/mixpanel'
  import Icon from '@/Components/Shared/Icon'
  import imgSrcset from '@/mixins/imgSrcset'
  import monetize from '@/mixins/monetize'
  import { Link } from '@inertiajs/inertia-vue'

  export default {
    mixins: [mixpanel, imgSrcset, monetize],

    components: {
      Icon,
      Link
    },

    props: {
      category: {
        type: Object,
        required: true
      },

      membershipPlan: {
        type: Object,
        default: null
      },

      geoProps: {
        type: Object,
        required: true
      },

      customImage: {
        type: Boolean,
        default: false
      },

      component: {
        type: String,
        default: null
      },
      imageUrl: {
        type: [String, Object],
        default: null
      },
      respimageUrl: {
        type: [String, Object],
        default: null
      }
    },

    computed: {
      membershipBannerPoints() {
        return [
          'Unlimited access to all courses and resources',
          'Over 1,000 immersive lessons to transform the way you live',
          'Expert instruction from the best designers, makers, growers and chefs',
          'New content added each month to help you develop your skills'
        ]
      }
    },

    methods: {
      getGeoPrice(gbpBasePrice) {
        const decimalPlaces = this.geoProps.currency === 'USD' ? 0 : 2

        return this.geoMonetize(
          gbpBasePrice * this.geoProps.exchangeRate,
          this.geoProps.currencySymbol,
          decimalPlaces
        ).replace(/\.00$/, '')
      },

      trackSubscribeButton() {
        this.trackEvent('subscribe_button', {
          userType: this.currentUser ? 'authed' : 'anonymous',
          page: window.location.pathname,
          component: this.component
        })
      }
    }
  }
  </script>
<style scoped>
.backgroundState {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}
.backgroundState:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(89.78deg, rgba(247, 243, 237, 0.85) 0.19%, rgba(247, 243, 237, 0.75) 53.75%, rgba(247, 243, 237, 0) 99.81%);
}
@media(max-width:1024px) {
  .backgroundState:before {
    width:70%;
  }
}
@media(max-width:768px) {
  .backgroundState {
    background-image: none !important;
  }
  .backgroundState:before {
    background: transparent;
  }
}
</style>
