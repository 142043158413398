<template>
  <div>
    <MetaTags :metaTags="metaTags" />

    <div data-testid="banner-section">
      <Banner
        uid="about"
        :image-src="AboutUsBanner"
        :image-alt="$I18n.t('about_banner', i18nScope)"
        height="medium"
        content-align="center"
        vertical-align="center"
      >
        <h1 data-testid="about-title" class="banner__title">
          {{ $I18n.t("about", i18nScope) }}
        </h1>
      </Banner>
    </div>

    <div class="container">
      <template v-for="item in content">
        <div :key="item.about.id">
          <SerifHeader :title="item.about.title" content-align="center" />
          <p class="w-full max-w-prose mx-auto text-xl text-center mb-10">
            {{ item.about.description }}
          </p>

          <div data-testid="about" class="flex flex-col lg:w-10/12 mx-auto">
            <VideoPlayer
              data-testid="about-video-player"
              class="mb-8"
              :isAutoPlay="false"
              :options="{
                accountId: brightcove.lessonPlayer.accountId,
                playerId: brightcove.lessonPlayer.playerId,
                videoId: about.videoId,
                embedId: 'default',
              }"
              :mixpanel="{
                type: 'about-us',
                videoId: about.videoId,
              }"
            />
          </div>
        </div>

        <Divider :key="item.id" spacing="xl" />

        <div :key="item.values.id">
          <SerifHeader :title="item.values.title" content-align="center" />

          <div class="flex flex-wrap justify-around w-full pt-8">
            <div
              :key="topic.id"
              v-for="(topic, index) in item.values.topics"
              class="flex flex-col w-full md:w-2/5 flex-shrink-0"
              :class="{ 'md:mb-20': index === 0 || index === 1 }"
            >
              <h3 class="heading--medium text-center text-yellow-800 mb-4">
                {{ topic.title }}
              </h3>
              <p class="text-xl text-center">{{ topic.description }}</p>

              <Divider
                v-if="index != Object.keys(item.values.topics).length - 1"
                spacing="xl"
                class="md:hidden"
                color="light"
                width="half"
              />
            </div>
          </div>
        </div>
      </template>

      <Divider spacing="xl" />

      <SerifHeader
        :title="$I18n.t('explore_categories', i18nScope)"
        content-align="center"
        aria-label="categories"
      />

      <Categories
        :categories="relatedCategories"
        :gtmPageName="currentPage"
        :gtmSectionName="gtmSectionExploreCategories"
      />
    </div>
  </div>
</template>
<script>
import { metaTags } from '@/mixins/metaTags'
import brightcove from '@/mixins/brightcove'

import MetaTags from '@/Components/Shared/MetaTags'
import Banner from '@/Components/Shared/Banner'
import Categories from '@/Components/Shared/Categories/Index'
import Divider from '@/Components/Shared/Divider'
import Layout from '@/Layouts/TransparentLayout'
import SerifHeader from '@/Components/Shared/SectionHeader/Serif'
import VideoPlayer from '@/Components/Shared/VideoPlayer'

export default {
  layout: Layout,

  mixins: [brightcove, metaTags],

  components: {
    Banner,
    Categories,
    Divider,
    MetaTags,
    SerifHeader,
    VideoPlayer
  },

  props: {
    relatedCategories: {
      type: Array,
      default: () => {
        return []
      }
    },
    about: {
      type: Object,
      required: true
    },
    content: {
      type: Array,
      default: () => [
        {
          about: {
            title: 'Who we are',
            description:
              'We’re on a mission to help you build your creative confidence and live a more creatively fulfilled life. Through beautifully curated video courses, we give you unprecedented access to the expertise of today’s best designers, makers, gardeners and chefs - allowing you to unlock your creativity whenever and wherever you want.',
            videoId: '6264273051001'
          },
          values: {
            title: 'The values we live by',
            topics: [
              {
                title: 'Bring joy',
                description:
                  'We strive to be generous, kind, good-feeling givers in everything we do'
              },
              {
                title: 'Build creative confidence',
                description:
                  'We want to empower people, encourage experiments and help others grow'
              },
              {
                title: 'Leave a positive impact',
                description:
                  'We are planet positive, community-minded and purposeful in our actions'
              },
              {
                title: 'Celebrate life',
                description:
                  'We want to inspire people to make more, do more and love more'
              }
            ]
          }
        }
      ]
    }
  },

  computed: {
    currentPage() {
      return 'About Us'
    },

    gtmSectionExploreCategories() {
      return 'Explore Categories'
    }
  },

  created() {
    this.AboutUsBanner = {
      url: require('@/assets/images/backgrounds/about-us-background.jpg'),
      sizes: {
        large: require('@/assets/images/backgrounds/about-us-background.jpg'),
        medium: require('@/assets/images/backgrounds/about-us-background-medium.jpg'),
        small: require('@/assets/images/backgrounds/about-us-background-small.jpg')
      }
    }

    this.i18nScope = { scope: 'pages.static' }
  }
}
</script>
