<template>
  <div class="flex flex-col items-center pb-10">
    <MetaTags :metaTags="metaTags" />

    <div class="w-full max-w-full px-4 xl:px-0 xl:max-w-screen-lg">
      <div class="flex items-center justify-center max-w-screen-lg w-full my-6 cursor-pointer" @click="navigateHome">
        <Logotype class="w-auto h-7 navbar__logo" />
      </div>
      <div class="bg-blue-300 h-1.5 w-9/12 sm:w-112 md:w-full rounded-md mb-9 relative mx-auto">
          <span class="absolute left-0 rounded-md top-0 h-full  bg-blue-800 w-1/4 " ></span>
      </div>
      <div class="flex flex-col gap-5 lg:gap-9 w-full">
        <div class="flex flex-col gap-5 lg:gap-3 w-full">
          <h4
            class="font-serif text-3xl leading-9 lg:text-4xl lg:leading-10 font-medium text-black text-center px-4 lg:px-0"
          >
            Unlock your creativity with unlimited access to expert-led lessons
          </h4>
          <p class="text-lg mb-3 lg:mb-0 lg:text-2xl font-normal text-center text-black">What would you like to learn?</p>
        </div>
        <div class="flex flex-col gap-2 w-full max-w-screen-xs mx-auto">
          <div v-for="topic in topics" :key="topic.id" class="customCheckbox flex items-center px-5 py-4 bg-neutral-100">
            <Checkbox
              class=" text-gray-900 font-normal text-base text-left"
              @input="() => handleCheckboxChange(topic.id)"
              :value="selectedTopics.includes(topic.id)"
            >
            {{ topic.name }}
            </Checkbox>
          </div>
          <p v-show="errors" class=" text-danger">{{ errors }}</p>
        </div>
        <div class='pb-11  lg:pb-0'>
          <a class="button button--blue w-full text-base max-w-15 mx-auto"  @click="handleNext"> Next</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'
import mixpanel from '@/mixins/mixpanel'

import Layout from '@/Layouts/ReducedLayout'
import MetaTags from '@/Components/Shared/MetaTags'
import Logotype from '@/Components/Shared/Logotype'
import Checkbox from '@/Components/Shared/Forms/Checkbox'
import currentUser from '@/mixins/currentUser'
export default {
  layout: Layout,

  mixins: [
    metaTags,
    currentUser,
    mixpanel
  ],

  components: {
    MetaTags,
    Logotype,
    Checkbox
  },

  props: {
    topics: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      errors: '',
      selectedTopics: JSON.parse(sessionStorage.getItem('selectedTopics')) || []

    }
  },

  methods: {
    handleCheckboxChange(topic_id) {
      const index = this.selectedTopics.indexOf(topic_id)

      if (index > -1) {
        this.selectedTopics.splice(index, 1)
      } else {
        this.selectedTopics.push(topic_id)
      }
    },
    navigateHome() {
      this.$inertia.visit(this.$routes.root())
    },
    trackTopicSelection() {
      this.trackEvent('onboarding_topics_selection', {
        page: window.location.pathname,
        topics: this.selectedTopicsName()
      })
    },
    selectedTopicsName() {
      return this.topics.filter(t => this.selectedTopics.includes(t.id)).map(t => t.name)
    },
    handleNext() {
      sessionStorage.setItem('selectedTopics',JSON.stringify(this.selectedTopics))
      if (this.selectedTopics.length > 0) {
        this.trackTopicSelection()

        this.$inertia.visit(this.$routes.onboarding_courses(), {
          method: 'get',
          data: {
            topics: this.selectedTopics.join()
          }
        })
      } else {
        this.errors = 'Please select an item from the list'
     }
    }
  },
mounted() {
  if (!sessionStorage.getItem('formStarted')) {
    sessionStorage.removeItem('onboarding_email')
    sessionStorage.removeItem('selectedTopics')
    sessionStorage.setItem('formStarted', 'true')
  }
},
  created() {
  const storedTopics = sessionStorage.getItem('selectedTopics')
  this.selectedTopics = storedTopics ? JSON.parse(storedTopics) : []
}

}
</script>
