
<template>
  <div data-testid="cart-drawer-strip" class="w-full pt-6 border-t border-blue-800 border-opacity-20">
    <div class="flex flex-col p-5" :class="[backgroundColor , textColor]">
      <p data-testid="cart-drawer-strip-title" class="heading--medium">{{ title }}</p>
      <p data-testid="cart-drawer-strip-text" class="text-lg tracking-wide">
        {{ text }}
        <span data-testid="cart-drawer-strip-highlightedText" v-if="highlightedText" class="heading underline">{{ highlightedText }}</span>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    cartDrawer: {
      type: Object,
      required: true
    },
    courseCount: {
      type: Number,
      required: true
    }
  },

  computed: {
    bannerStrip() {
      return this.cartDrawer.bannerStrip
    },

    backgroundColor() {
      return `bg-${this.bannerStrip.backgroundColor}`
    },

    textColor() {
      return `text-${this.bannerStrip.textColor}`
    },

    multipleCourses() {
      return this.courseCount > 1
    },

    // Diferent content based on cart size
    title() {
      if (this.multipleCourses && this.cartDrawer.titleMultipleCourses) return this.cartDrawer.titleMultipleCourses

      return this.bannerStrip.title
    },
    text() {
      if (this.multipleCourses && this.cartDrawer.textMultipleCourses) return this.cartDrawer.textMultipleCourses

      return this.bannerStrip.text
    },
    highlightedText() {
      if (this.multipleCourses && this.cartDrawer.highlightedTextMultipleCourses) return this.cartDrawer.highlightedTextMultipleCourses

      return this.bannerStrip.highlightedText
    }
  }
}
</script>
