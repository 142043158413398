<template>
  <Button
    data-testid="button-watch-trailer"
    v-if="course.trailer"
    @click="openTrailerModal"
    v-bind="$attrs"
    icon="play"
  >
    {{ $I18n.t('watch_trailer', i18nScope) }}
  </Button>
</template>


<script>
import brightcove from '@/mixins/brightcove'
import mixpanel from '@/mixins/mixpanel'

import Button from '@/Components/Shared/Button'
import TrailerModal from '@/Components/Shared/Trailer/Modal'

export default {
  mixins: [
    brightcove,
    mixpanel
  ],

  components: {
    Button
  },

  props: {
    course: {
      type: Object,
      required: true
    },
    gtmPageName: {
      type: String,
      required: true
    },
    gtmSectionName: {
      type: String,
      required: true
    }
  },

  methods: {
    openTrailerModal() {
      this.$modal.show(TrailerModal,
        {
          course: this.course,
          brightcove: this.brightcove,
          gtmPageName: this.gtmPageName,
          gtmSectionName: 'Trailer Modal'
        },
        {
          width: '95%',
          maxWidth: 940,
          classes: 'bg-opacity-0'
        }
      )

      this.trackEvent('click_trailer_button', {
        page: window.location.pathname,
        component: this.gtmSectionName,
        course: this.course.name,
        teacher: this.course.teacher.fullName
      })
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.courses.show' }
  }
}
</script>
