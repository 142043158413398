var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('MetaTags',{attrs:{"metaTags":_vm.metaTags}}),_vm._v(" "),_c('div',{attrs:{"data-testid":"banner-section"}},[_c('Banner',{attrs:{"uid":"about","image-src":_vm.AboutUsBanner,"image-alt":_vm.$I18n.t('about_banner', _vm.i18nScope),"height":"medium","content-align":"center","vertical-align":"center"}},[_c('h1',{staticClass:"banner__title",attrs:{"data-testid":"about-title"}},[_vm._v("\n        "+_vm._s(_vm.$I18n.t("about", _vm.i18nScope))+"\n      ")])])],1),_vm._v(" "),_c('div',{staticClass:"container"},[_vm._l((_vm.content),function(item){return [_c('div',{key:item.about.id},[_c('SerifHeader',{attrs:{"title":item.about.title,"content-align":"center"}}),_vm._v(" "),_c('p',{staticClass:"w-full max-w-prose mx-auto text-xl text-center mb-10"},[_vm._v("\n          "+_vm._s(item.about.description)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-col lg:w-10/12 mx-auto",attrs:{"data-testid":"about"}},[_c('VideoPlayer',{staticClass:"mb-8",attrs:{"data-testid":"about-video-player","isAutoPlay":false,"options":{
              accountId: _vm.brightcove.lessonPlayer.accountId,
              playerId: _vm.brightcove.lessonPlayer.playerId,
              videoId: _vm.about.videoId,
              embedId: 'default',
            },"mixpanel":{
              type: 'about-us',
              videoId: _vm.about.videoId,
            }}})],1)],1),_vm._v(" "),_c('Divider',{key:item.id,attrs:{"spacing":"xl"}}),_vm._v(" "),_c('div',{key:item.values.id},[_c('SerifHeader',{attrs:{"title":item.values.title,"content-align":"center"}}),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap justify-around w-full pt-8"},_vm._l((item.values.topics),function(topic,index){return _c('div',{key:topic.id,staticClass:"flex flex-col w-full md:w-2/5 flex-shrink-0",class:{ 'md:mb-20': index === 0 || index === 1 }},[_c('h3',{staticClass:"heading--medium text-center text-yellow-800 mb-4"},[_vm._v("\n              "+_vm._s(topic.title)+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"text-xl text-center"},[_vm._v(_vm._s(topic.description))]),_vm._v(" "),(index != Object.keys(item.values.topics).length - 1)?_c('Divider',{staticClass:"md:hidden",attrs:{"spacing":"xl","color":"light","width":"half"}}):_vm._e()],1)}),0)],1)]}),_vm._v(" "),_c('Divider',{attrs:{"spacing":"xl"}}),_vm._v(" "),_c('SerifHeader',{attrs:{"title":_vm.$I18n.t('explore_categories', _vm.i18nScope),"content-align":"center","aria-label":"categories"}}),_vm._v(" "),_c('Categories',{attrs:{"categories":_vm.relatedCategories,"gtmPageName":_vm.currentPage,"gtmSectionName":_vm.gtmSectionExploreCategories}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }