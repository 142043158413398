<template>
  <div class="bg-red-500 min-h-40 w-full">
    <div class="container px-4 lg:px-16 py-12 lg:py-24 flex flex-col lg:flex-row">
      <!-- left column column -->
      <div class="w-full lg:w-2/5 px-0 pb-8 lg:pb-0 lg:p-0 lg:pl-0 flex items-center justify-center">
        <div>
          <h1
            class="text-white font-serif text-4xl md:text-5xl 2xl:text-6xl text-center lg:text-left leading-tight tracking-tight text-balance mb-2"
          >
            {{ headline }}
          </h1>
          <p class="text-white text-center lg:text-left md:text-2xl text-lg">
            Access online courses led by industry professionals
          </p>
          <div class="flex justify-center lg:justify-start">
            <ButtonLink
              data-testid="button-interiors-landing"
              type="submit"
              class="text-sm border-transparent !text-black bg-white mt-6"
              href="/free-lessons"
              variant="white"
              outline= "true"
              @click="trackFreeLessonsClick"
            >
              WATCH FREE LESSONS NOW
            </ButtonLink>
          </div>
          <div class="flex flex-row mt-10 gap-x-9 lg:gap-x-0 md:mt-14  2xl:mt-20 flex-wrap justify-center lg:justify-between gap-y-5">
            <div class="lg:w-32 flex flex-col items-center justify-end">
              <p class="text-white text-4xl lg:text-5xl font-serif text-center justify-center">+600</p>
              <p class="font-sans text-white text-sm lg:text-lg 2xl:text-xl justify-center text-center">Interior  <br />design lessons</p>
            </div>
            <div class=" lg:w-32  flex flex-col items-center justify-end">
              <p class="text-white text-4xl lg:text-5xl font-serif text-center justify-center">10</p>
              <p class="font-sans text-white text-sm lg:text-lg 2xl:text-xl justify-center text-center">Property  <br />case studies</p>
            </div>
            <div class="lg:w-32  flex flex-col items-center justify-end">
              <p class="text-white text-4xl lg:text-5xl font-serif text-center justify-center">18</p>
              <p class="font-sans text-white text-sm lg:text-lg 2xl:text-xl justify-center text-center">Expert  <br />instructors</p>
            </div>
          </div>
        </div>
      </div>

      <!-- right column (video) -->
      <div class="w-full lg:w-3/5 p-2 pl-0 lg:pl-8 flex items-center justify-center">
        <div class="w-full relative">
          <BrightcovePlayer
            v-if="shouldShowPlayer"
            data-testid="banner-player"
            :id="`banner-player-${uid}`"
            :isHideControls="false"
            :isAutoPlay="false"
            :options="playerOptions"
            @onCanPlayThrough="onCanPlayThrough"
            @onError="onPlayerError"
            class="absolute top-0 left-0 w-full h-full"
          />
          <div
            v-else
            class="absolute top-0 left-0 w-full h-full bg-gray-200 flex items-center justify-center"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'
  import ButtonLink from '@/Components/Shared/ButtonLink.vue'
  import BrightcovePlayer from '@/vendor/brightcove/BrightcovePlayer.vue'
  import mixpanel from '@/mixins/mixpanel' // Import the mixpanel mixin if not already imported

  export default defineComponent({
    name: 'CategoryLandingHero',
    components: {
      ButtonLink,
      BrightcovePlayer
    },
    mixins: [mixpanel], // Add the mixpanel mixin if not already included
    props: {
      headline: {
        type: String,
        default: 'Become your own interior designer'
      }
    },
    data() {
      return {
        uid: Math.random().toString(36).substring(2, 15),
        canPlayVideo: false,
        videoId: '6352656100112',
        brightcove: {
          accountId: '6222962662001',
          playerId: '3BqAJwkg7'
        }
      }
    },
    computed: {
      shouldShowPlayer() {
        const result = this.videoId && Object.keys(this.brightcove).length > 0
        console.log('Should show player:', result)
        console.log('videoId:', this.videoId)
        console.log('brightcove:', this.brightcove)
        return result
      },
      playerOptions() {
        return {
          videoId: this.videoId,
          accountId: this.brightcove.accountId,
          playerId: this.brightcove.playerId,
          embedId: 'default'
        }
      }
    },
    methods: {
      onCanPlayThrough() {
        this.canPlayVideo = true
        console.log('Video can play through')
      },
      onPlayerError(error) {
        console.error('Brightcove player error:', error)
      },
      trackFreeLessonsClick() {
        this.trackEvent('free_lessons_button_clicked', {
          location: 'CategoryLandingHero',
          headline: this.headline
        })
      }
    },
    mounted() {
      console.log('CategoryLandingHero mounted')
      console.log('Player options:', this.playerOptions)
    }
  })
</script>
