const statuses = {
  // Used when we have a course prop
  computed: {
    published() {
      return this.course?.status === 'published'
    },
    preLaunch() {
      return this.course?.status === 'pre_launch'
    },
    purchasable() {
      return this.course?.purchasable
    },
    comingSoon() {
      return this.course?.status === 'coming_soon'
    }
  },

  // Used when we don't have a course prop but have a course object
  methods: {
    isPublished(course) {
      return course.status === 'published'
    },
    isPreLaunch(course) {
      return course.status === 'pre_launch'
    },
    isComingSoon(course) {
      return course.status === 'coming_soon'
    }
  }
}

export default statuses
