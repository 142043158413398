<template>
  <form v-if="purchasable" @submit.prevent="submit" novalidate="true" class="flex flex-row contents">
    <InputGroup
      testid="course_slug"
      v-model="form.course.slug"
      :errors="form.errors.slug"
      class="hidden"
      type="hidden"
    />

    <!-- on sitewide sale -->
    <!-- Variant testing -->
    <AbTest :testName="targetTest" :variantId="abTestVariants[targetTest]" lass="w-full contents lg:w-auto ">
      <template v-slot:default>
        <div
          class="flex flex-row w-full"
          :class="buttonBorder ? 'border border-blue-800' : 'border border-transparent'"
        >
          <template v-if="inCart">
            <ButtonLink
              data-testid="button-go-to-cart"
              :href="$routes.cart()"
              v-bind="$attrs"
              @click="processInCartClick"
              icon=""
              variant="blue"
              :class="buttonClass"
            >
              {{ $I18n.t(i18nText, i18nScope) }}
            </ButtonLink>
          </template>

          <template v-else>
            <Button
              :data-testid="testId"
              :loading="form.processing"
              v-bind="$attrs"
              type="submit"
              variant="blue"
              icon=""
              :class="buttonClass"
            >
              {{ $I18n.t(i18nText, i18nScope) }}
            </Button>
          </template>

          <div v-if="showPrice" class=" bg-white px-3.5 w-2/5">
            <div class="flex flex-col justify-center h-full items-center">
              <div class="flex flex-row items-center">
                <span v-if="saleReduction" class="line-through mr-2">{{ monetisedGeoPrice(course.geoPrice.price, 0) }}</span>
                <span 
                  :class="{'font-medium': saleReduction, 'font-normal': !saleReduction}"
                  class="text-base text-blue-800">{{ monetisedGeoPrice(finalPrice) }}</span>
              </div>
              <div v-if="saleReduction" class="text-xs text-red-800 uppercase font-normal text-center -mt-1">
                <span>Save {{ monetisedGeoPrice(saleReduction) }}</span>
              </div> 
              </div>           
          </div>

          <!-- no sale -->
          <div v-else>
            <p :data-goptimize-course-price="course.unformatted_price" class="heading--medium text-blue-800">{{ course.price }}</p>
          </div>
        </div>
        </template>

        <!-- Note, default slot is shown if test is off or variant is not provided -->
        <template v-slot:1>
          <div class="flex flex-row">
          <template v-if="inCart">
            <ButtonLink
              data-testid="button-go-to-cart"
              :href="$routes.cart()"
              v-bind="$attrs"
              @click="processInCartClick"
              icon=""
              :class="buttonClass"
              variant="blue"
            >
              {{ $I18n.t(i18nText, i18nScope) }}
            </ButtonLink>
          </template>

          <template v-else>
            <div v-if="!isTeaser" class="relative saleTagButton">
              <Button
                :data-testid="testId"
                :loading="form.processing"
                v-bind="$attrs"
                type="submit"
                icon=""
                variant="blue"
                :class="buttonClass"
              >
                {{ $I18n.t(i18nText, i18nScope) }}
              </Button>

              <div v-if="saleReduction" class="absolute transform rotate-17 top-5 -right-20 text-white ">
                <div class="absolute bottom-7 text-center right-8 font-normal text-sm">
                  <div class="">save</div>
                  <div class="-mt-1.5">{{ monetisedGeoPrice(saleReduction) }}</div>
                </div>
                <img loading="lazy" :src="saleTag" class="scale-105">
              </div>
            </div>

            <Button
              v-else
              :data-testid="testId"
              :loading="form.processing"
              v-bind="$attrs"
              type="submit"
              icon=""
              variant="blue"
              :class="buttonClass"
            >
              {{ $I18n.t(i18nText, i18nScope) }}
            </Button>
          </template>

          <div v-if="showPrice" class="px-4 flex flex-col justify-center bg-blue-100 text-gray-700">
            <div v-if="saleReduction" class="line-through text-xs mx-auto">{{ monetisedGeoPrice(course.geoPrice.price, 0) }}</div>
            <div class="text-base tracking-wider leading-4 text-blue-800 font-medium">{{ monetisedGeoPrice(finalPrice) }}</div>
          </div>

          <!-- no sale -->
          <div v-else>
            <p :data-goptimize-course-price="course.unformatted_price" class="heading--medium text-blue-800">{{ course.price }}</p>
          </div>
        </div>
      </template>
    </AbTest>
  </form>
</template>

<script>
import cart from '@/mixins/cart'
import mixpanel from '@/mixins/mixpanel'
import gtm from '@/mixins/gtm'
import statuses from '@/mixins/statuses'
import monetize from '@/mixins/monetize'

import InputGroup from '@/Components/Shared/Forms/InputGroup'
import Button from '@/Components/Shared/Button'
import ButtonLink from '@/Components/Shared/ButtonLink'
// AB Testing bits
import AbTest from '@/Components/Shared/AbTest'
import abTest from '@/mixins/abTest'

export default {
  components:{
    Button,
    ButtonLink,
    InputGroup,
    AbTest
  },

  mixins: [
    statuses,
    mixpanel,
    monetize,
    cart,
    gtm,
    abTest
  ],

  props: {
    testId: {
      type: String,
      default: 'button-submit-purchase'
    },
    isTeaser: {
      type: Boolean,
      default: true
    },
    course: {
      type: Object,
      required: true
    },
    inCart: {
      type: Boolean,
      default: false
    },
    showPrice: {
      type: Boolean,
      default: true
    },
    gtmPageName: {
      type: String,
      required: true
    },
    gtmSectionName: {
      type: String,
      required: false
    },
    buttonBorder: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      form: this.$inertia.form({
        course: this.course.slug
      }),
      buttonClass: 'text-sm buttonWidth border-transparent whitespace-nowrap	 hover:bg-blue-300 hover:text-gray-700'
    }
  },

  computed: {
    // AB TEST SETUP
    targetTest() {
      return 'course_actions' // this is the name of the target AB test
    },
    basePrice() {
      const priceFromGeo = this.course?.geoPrice?.price
      const unformattedPrice = this.course?.unformatted_price
      return priceFromGeo || unformattedPrice || 0
  
    },
    saleReduction() {
      if (this.activeSale) {
        return this.basePrice * this.activeSale.discountValue / 100
      }
      return 0 // no sale
    },
    finalPrice() {
      return this.basePrice - this.saleReduction
    },
    activeSale() {
      return this.course.activeSale
    },
    i18nText() {
      if (this.inCart) {
        return 'in_cart'
      } else {
        return 'buy_now'
      }
    },

    iconName() {
      if (this.inCart) {
        return 'check'
      } else {
        return 'basket'
      }
    },

    teacherName() {
      return this.course.teacher.name || this.course.teacher.fullName
    }
  },

  methods: {
    monetisedGeoPrice(price, decimalPlaces = 2) {
      decimalPlaces = this.course.geoPrice && this.course.geoPrice.currency === 'USD' ? 0 : decimalPlaces
      return this.geoMonetize(price, this.course.geoPrice ? this.course.geoPrice.currencySymbol : '£', decimalPlaces)
    },    
    submit() {
      this.form.post(this.$routes.cart_cart_items(), {
        preserveScroll: true,
        onSuccess: () => {
          // Prepare the base event data
          let eventData = {
            page: window.location.pathname,
            component: this.gtmSectionName,
            items: [this.course.name],
            teachers: [this.teacherName],
            ...this.mixpanelCartData(this.orderable)
          }

          // Conditionally add the abTest attribute if it's not null
          const abTestValue = this.activeAbTest(this.targetTest)
          if (abTestValue !== null) {
            eventData.abTest = abTestValue
          }

          // Track the event with the prepared data
          this.trackEvent('add_course_to_basket', eventData)

          this.gtmAddToCart(this.gtmPageName, this.course, this.gtmSectionName)
          this.$emit('success')
        }
      })
    },
    processInCartClick(){
      return this.trackEvent('proceed_to_basket', this.mixpanelCartData(this.orderable))
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.courses.show' }
    this.saleTag = require('@/assets/images/SaleTag.svg')
  }
}
</script>
