<template>
  <div class="flex flex-col items-stretch h-full">
    <template v-if="isVideoFirst">
      <div class="mb-4 relative w-full" style="padding-top: 177.78%">
        <!-- Placeholder Image -->
        <div v-if="!isPlaying" class="absolute inset-0 cursor-pointer z-10" @click="playVideo">
          <img :src="placeholderImage" alt="Video thumbnail" class="w-full h-full object-cover" />
          <div class="absolute inset-0 flex items-center justify-center">
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="80" height="80" rx="40" fill="#F6F7F9" fill-opacity="0.8"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M26.583 20.834L57.2497 39.8948L26.583 59.1673V20.834Z" fill="#18336C"/>
            </svg>
          </div>
        </div>

        <!-- Video -->
        <div v-show="isPlaying" class="absolute inset-0 z-20">
          <video
            v-if="videoSrc"
            :id="`testimonial-player-${uid}`"
            :src="videoSrc"
            :controls="!isHideControls"
            @canplay="onCanPlay"
            @error="onVideoError"
            class="w-full h-full object-cover"
            playsinline
          ></video>
        </div>
      </div>
      <TestimonialCard :quote="quote" :author="author" :logo-src="logoSrc" />
    </template>

    <template v-else>
      <div class="mb-4">
        <TestimonialCard :quote="quote" :author="author" :logo-src="logoSrc" />
      </div>
      <div class="relative h-96 w-full" style="padding-top: 177.78%">
        <!-- Placeholder Image -->
        <div v-if="!isPlaying" class="absolute inset-0 cursor-pointer z-10" @click="playVideo">
          <img :src="placeholderImage" alt="Video thumbnail" class="w-full h-full object-cover" />
          <div class="absolute inset-0 flex items-center justify-center">
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="80" height="80" rx="40" fill="#F6F7F9" fill-opacity="0.8"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M26.583 20.834L57.2497 39.8948L26.583 59.1673V20.834Z" fill="#18336C"/>
            </svg>
          </div>
        </div>

        <!-- Video -->
        <div v-show="isPlaying" class="absolute inset-0 z-20">
          <video
            v-if="videoSrc"
            :id="`testimonial-player-${uid}`"
            :src="videoSrc"
            :controls="!isHideControls"
            @canplay="onCanPlay"
            @error="onVideoError"
            class="w-full h-full object-cover"
            playsinline
          ></video>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import TestimonialCard from './TestimonialCard.vue'

  export default defineComponent({
    name: 'TestimonialColumn',
    components: {
      TestimonialCard
    },
    props: {
      videoSrc: {
        type: String,
        default: ''
      },
      placeholderImage: {
        type: String,
        required: true
      },
      quote: {
        type: String,
        required: true
      },
      author: {
        type: String,
        required: true
      },
      logoSrc: {
        type: String,
        required: true
      },
      isVideoAutoplay: {
        type: Boolean,
        default: false
      },
      isVideoFirst: {
        type: Boolean,
        default: false
      },
      isHideControls: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        uid: Math.random().toString(36).substring(2, 15),
        canPlayVideo: false,
        isPlaying: false
      }
    },
    methods: {
      onCanPlay() {
        this.canPlayVideo = true
      },
      onVideoError(error) {
        console.error('Video player error:', error)
      },
      playVideo() {
        this.isPlaying = true
        this.$nextTick(() => {
          const video = this.$el.querySelector('video')
          if (video) {
            video.play()
          }
        })
      }
    }
  })
</script>

<style scoped>
  .video-container {
    position: relative;
    width: 100%;
    padding-top: 177.78%; /* 16:9 aspect ratio */
    overflow: hidden;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
</style>
