<template>
  <div>
    <div
      v-for="(coursesArray, month) in courses"
      :key="month"
      :class="`bg-${getMonthBgColor(month)} w-full`"
      :id="month"
    >
      <div class="container px-4 lg:px-16 pt-10 pb-4 lg:pt-20 lg:pb-16">
        <div class="flex flex-col">
          <div
            class="flex items-start flex-col md:flex-row md:flex-wrap w-full mx-0 md:-mx-4"
          >
            <div
              class="relative px-0 md:px-4 w-full md:w-1/2 mb-2 md:mb-6 lg:mb-12"
            >
              <h4
                class="text-4xl font-medium font-serif text-blue-800 capitalize"
              >
                {{ month }}
              </h4>
            </div>
            <div class="relative px-0 md:px-4 w-full md:w-1/2 mb-6 lg:mb-12">
              <p
                class="text-lg lg:text-2xl leading-7 font-sans font-normal text-blue-800"
              >
                {{ getMonthDescription(month) }}
              </p>
            </div>
          </div>
          <div
            class="flex flex-row items-start justify-center lg:justify-start flex-wrap w-full -mx-1 md:-mx-4"
          >
            <CategoryCourseTeaser
              v-for="(course, index) in coursesArray"
              :key="course.id"
              :YearLearning="true"
              :course="course"
              :gtmPosition="index + 1"
              :gtmSectionName="gtmSectionMonthlyCourses"
              :gtmPageName="currentPage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import brightcove from '@/mixins/brightcove'
import CategoryCourseTeaser from '@/Components/Shared/Categories/CategoryCourseTeaser'
export default {
  mixins: [brightcove],
  components: {
    CategoryCourseTeaser
  },
  props: {
    courses: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      descriptions: {
        january:
          'As we turn a new page, get ready for a refresh with a new take on nutrition from Amelia Freer and tips on restyling your home with Gabby Deeming. Prepare to fill your year with beautiful blooms with Sarah Raven\'s first-ever online course in cut flowers.',
        february:
          'Turn your attention to growing your own food with the launch of Claire Ratinon\'s long-awaited course and take a moment to photograph the Snowdrops with the help of Jason Ingram. For interior design tips learn from award-winning interiors writer, Kate Watson-Smyth.',
        march:
          'With spring just around the corner dig deeper into the garden with the help of Dan Pearson\'s two incredible courses and Sarah Raven\'s second course on growing in pots. You can also bring some colour and pattern indoors by learning how to create some beautiful block printed items.',
        april:
          'For a total spring refresh, follow Rita Konig as she renovates her home in her second, long-awaited course. Then exercise your green-fingers both indoors and out with lessons from James Wong on house plants and the Land Gardeners on growing flowers and healing your soil.',
        may: 'As the flowers of late spring bloom, join JamJar Edit and Willow Crossley in exploring creative ways to bring flowers indoors. Elevate your indoor ambiance by delving into the art of colour theory with Edward Bulmer, crafting a harmonious paint scheme that rejuvenates your entire home.',
        june: 'As summer begins, discover how to bring some bright and airy touches to your home with advice from Amanda Lindroth and Lisa Dawson. Then embark on a journey to learn the art of decorative painting with the Tess Newall.',
        july: 'Although summer is in full swing, look ahead and discover the art of preserving flowers for winter months with Bex Partridge. Make the most of those long summer evenings by dedicating time to learning interiors design tips from Nina Campbell and weaving projects with Maria Sigma.',
        august:
          'Embrace a slower pace for summer with fresh new vegetarian dishes from Anna Jones and discover how to paint your produce with still life lessons from Katharine Amies. Take some time to learn from textiles designer Kathryn Ireland and create some new soft furnishings for your home with lessons in curtain making from Gemma Moulton.',
        september:
          'Celebrate sustainability in September and find ways to give new life to old items. Paint your own furniture with lessons from Annie Sloan, master the skill of reupholstering with Micaela Sharp and turn old silk into beautiful lampshades with Chloe Jonason.',
        october:
          'As the nights grow shorter, make more time for your home with lessons from a trio of interior design experts. Learn how to find your personal style and create a home you\'ll be proud of.',
        november:
          'Embrace autumn with hearty family recipes from Thomasina Miers and spend cosy evenings learning the art of quilting. Plan for a bountiful garden with Poppy Okotcha and discover which bulbs to plant to make your outdoor space come alive with Arthur Parkinson.',
        december:
          'As the festive season rolls around, find inspiration in the ornate interiors of Alidad and the glittering metal works of Jess Wheeler. Wow your guests with your wine knowledge then get ahead on a year of growing with lessons from Jane Scotter.'
      }
    }
  },
  methods: {
    getMonthDescription(month) {
      return Object.prototype.hasOwnProperty.call(this.descriptions, month)
        ? this.descriptions[month]
        : 'As the festive season rolls around, find inspiration in the ornate interiors of Alidad and the glittering metal works of Jess Wheeler. Wow your guests with your wine knowledge then get ahead on a year of growing with lessons from Jane Scotter.'
    },
    getMonthBgColor(month) {
      const monthColors = {
        january: 'yellow-100',
        february: 'grayscale-100',
        march: 'blue-300',
        april: 'green-300',
        may: 'grayscale-100',
        june: 'yellow-100',
        july: 'neutral-200',
        august: 'blue-300',
        september: 'grayscale-100',
        october: 'neutral-100',
        november: 'green-300',
        december: 'blue-300'
      }
      return Object.prototype.hasOwnProperty.call(monthColors, month)
        ? monthColors[month]
        : 'grayscale-100'
    }
  },
  computed: {
    currentPage() {
      return 'PLP'
    },
    gtmSectionMonthlyCourses() {
      return 'Monthly Courses'
    }
  }
}
</script>
