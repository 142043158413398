<template>
  <p>
    <template v-if="course.releaseDate">
      <span :data-testid="`${testId}-release-date`">{{ $I18n.t('live_on', i18nScope) }} {{ course.releaseDate }}</span>
    </template>
    <template v-else>
      <span :data-testid="`${testId}-pre-launch-tag`">{{ $I18n.t('live_shortly', i18nScope) }}</span>
    </template>
  </p>
</template>

<script>
export default {
  props: {
    course: {
      type: Object,
      required: true
    },
    i18nScope: {
      type: Object,
      required: true
    },
    testId: {
      type: String,
      required: false,
      default: 'course'
    }
  }
}
</script>
