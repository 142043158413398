<template>
  <div class="bg-red-100 lg:bg-transparent memberSlider">
    <div class="container pt-8 pb-12 lg:pt-0">
      <div class="flex flex-col mb-2 lg:mb-0 gap-1 lg:gap-3">
        <h2 class="text-3xl lg:text-4xl font-serif font-medium text-black text-center capitalize">
          Hear from our Members
        </h2>
        <p class="text-center text-base lg:text-xl text-black font-normal">
          Click play to hear from our current Create Academy Members
        </p>
      </div>
      <div class="testimonial-grid mt-8 lg:mt-14 relative" ref="swiperContainer">
        <div class="swiper-wrapper items-stretch">
          <div
            class="swiper-slide h-auto"
            v-for="(testimonial, index) in testimonials"
            :key="index"
          >
            <div class="bg-neutral-200">
              <div class="relative w-full slider-height">
                <!-- Placeholder Image -->
                <div
                  v-if="playingIndex !== index"
                  class="absolute inset-0 cursor-pointer z-10"
                  @click="playVideo(index)"
                >
                  <img
                    :src="testimonial.placeholderImage"
                    alt="Video thumbnail"
                    class="w-full h-full object-cover object-top"
                  />
                  <div class="absolute inset-0 flex items-center justify-center">
                    <svg
                      width="42"
                      height="46"
                      viewBox="0 0 42 46"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M40.0243 20.8346L3.67059 0.385643C2.96624 -0.00970311 2.10738 -0.000614703 1.41212 0.40382C0.712312 0.812799 0.285156 1.55805 0.285156 2.36692V43.2648C0.285156 44.0737 0.712312 44.8189 1.41212 45.2279C1.76657 45.4324 2.16191 45.5369 2.55726 45.5369C2.93897 45.5369 3.32523 45.4415 3.67059 45.2461L40.0243 24.7971C40.7377 24.3927 41.183 23.6384 41.183 22.8159C41.183 21.9934 40.7377 21.239 40.0243 20.8346Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>

                <!-- Video -->
                <div v-if="playingIndex === index" class="absolute inset-0 z-20">
                  <video
                    :id="`testimonial-player-${index}`"
                    :controls="!isHideControls"
                    @canplay="onCanPlay"
                    @error="onVideoError"
                    @mouseenter="handleVideoPlay"
                    @mouseleave="handleVideoPause"
                    class="w-full h-full object-cover"
                    preload="none"
                    ref="testimonialVideos"
                    playsinline
                    autoplay
                    :src="testimonial.videoSrc"
                  ></video>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, ref, onMounted, onBeforeUnmount, computed } from 'vue'
  import Swiper from 'swiper/bundle'
  import 'swiper/swiper-bundle.css'
  import { getS3Url } from '../../../s3utils'
  import mixpanelMixin from '../../../mixins/mixpanel'

  export default defineComponent({
    name: 'MemberSlider',
    mixins: [mixpanelMixin],
    props: {
      isHideControls: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        canPlayVideo: false,
        isPlaying: false
      }
    },
    methods: {
      onCanPlay() {
        this.canPlayVideo = true
      },
      onVideoError(error) {
        console.error('Video player error:', error)
      }
    },
    setup() {
      const swiperContainer = ref(null)
      const testimonials = computed(() => [
        {
          videoSrc: getS3Url('nicola.mp4'),
          placeholderImage: getS3Url('nicola.webp')
        },
        {
          videoSrc: getS3Url('laura.mp4'),
          placeholderImage: getS3Url('laura.webp')
        },
        {
          videoSrc: getS3Url('jo.mp4'),
          placeholderImage: getS3Url('jo.webp')
        },
        {
          videoSrc: getS3Url('gina.mp4'),
          placeholderImage: getS3Url('gina.webp')
        }
      ])
      const playingIndex = ref(null)
      const swiper = ref(null)
      const observer = ref(null)
      const testimonialVideos = ref([])

      const playVideo = index => {
        playingIndex.value = index
        const video = document.querySelector(`#testimonial-player-${index}`)
        if (video) {
          video.play()
        }

        this.trackEvent('Member Video Played', {
          videoIndex: index,
          videoSrc: testimonials.value[index].videoSrc
        })
      }

      const lazyLoadVideo = (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const video = entry.target
            const index = video.getAttribute('data-index')
            video.src = testimonials.value[index].videoSrc
            video.play()
            observer.unobserve(video)
          }
        })
      }

      const handleVideoPlay = () => {
        swiper.value.autoplay.stop()
      }

      const handleVideoPause = () => {
        swiper.value.autoplay.stop()
      }

      onMounted(() => {
        swiper.value = new Swiper(swiperContainer.value, {
          slidesPerView: 1,
          spaceBetween: 28,
          pagination: {
            el: '.swiper-pagination'
          },
          breakpoints: {
            768: {
              slidesPerView: 1,
              spaceBetween: 20
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20
            }
          }
        })

        observer.value = new IntersectionObserver(lazyLoadVideo)
        testimonialVideos.value.forEach((video, index) => {
          video.setAttribute('data-index', index)
          observer.value.observe(video)
        })
      })

      onBeforeUnmount(() => {
        if (observer.value) {
          observer.value.disconnect()
        }
      })

      return {
        swiperContainer,
        testimonials,
        playingIndex,
        playVideo,
        handleVideoPlay,
        handleVideoPause,
        testimonialVideos
      }
    }
  })
</script>

<style scoped>
  .video-container {
    position: relative;
    width: 100%;
    padding-top: 177.78%; /* 16:9 aspect ratio */
    overflow: hidden;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .slider-height {
    padding-top: 177.78%;
  }
  .swiper-pagination {
    display: flex;
    align-items: center;
    padding: 0 !important;
    gap: 6px;
    position: relative;
    margin-top: 40px;
    justify-content: center;
  }
  @media (min-width: 1024px) {
    video::-webkit-media-controls-fullscreen-button {
      display: none;
    }

    video::-moz-media-controls-fullscreen-button {
      display: none;
    }

    video::-ms-media-controls-fullscreen-button {
      display: none;
    }

    video::media-controls-fullscreen-button {
      display: none;
    }
  }
  @media (max-width: 1024px) {
    .slider-height {
      padding-top: 177.78%;
    }
  }
</style>
