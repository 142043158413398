<template>
  <div :class="containerClassNames" v-bind="$attrs">
    <Course
      v-for="(course, index) in courses"
      :key="course.id"
      :course="course"
      :brightcove="brightcove"
      :class="classNames"
      :headingLevel="headingLevel"
      :gtmPageName="gtmPageName"
      :gtmSectionName="gtmSectionName"
      :gtmPosition="index + 1"
      :show-purchase-button="showPurchaseButton"
      :abTestVariants="abTestVariants"
    />
  </div>
</template>

<script>
import gtm from '@/mixins/gtm'
import abTest from '@/mixins/abTest'
import Course from '@/Components/Shared/Talents/Show'

export default {
  mixins: [
    gtm,
    abTest
  ],

  components: {
    Course
  },

  props: {
    courses: {
      type: Array,
      default: () => []
    },
    compact: {
      type: Boolean,
      default: false
    },
    brightcove: {
      type: Object,
      required: true
    },
    headingLevel: {
      type: String,
      default: 'h2'
    },
    gtmPageName: {
      type: String,
      required: true
    },
    gtmSectionName: {
      type: String,
      required: true
    },
    showPurchaseButton: {
      type: Boolean,
      default: false
    },
    isSlider: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    console.log(this.courses)
  },

  computed: {
    containerClassNames() {
      return [
        this.isSlider ? 'swiper-wrapper' : 'flex flex-row flex-wrap'
      ]
    },

    classNames() {
      return [
        this.isSlider ? 'swiper-slide w-full sm:w-1/2 md:w-1/3 xl:w-1/4' : 'flex-grid-3' ,
        { 'flex-grid-3__compact': this.compact }
      ]
    }
  }
}
</script>
